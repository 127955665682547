import QantasLogo from '../assets/images/qantas_logo.png'

const Navbar: React.FC = () => {
  return (
    <div className='flex'>
      <div className='items-start'>
        <a href='/ticket'>
          <figure>
            <img src={QantasLogo} alt='Qantas' height={500} width={500} />
          </figure>
        </a>
      </div>
    </div>
  )
}

export default Navbar
