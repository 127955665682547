import React from 'react'
import ReactDOM from 'react-dom/client'
// import './index.css';
import './i18n/config'
import App from './App'
import { Provider } from 'react-redux'
import store from './redux/store'
import reportWebVitals from './reportWebVitals'
import 'react-toastify/dist/ReactToastify.css'
import { SWRConfig } from 'swr'
import { fetcher } from './components/fetchers'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </SWRConfig>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
