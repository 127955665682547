import { useState } from 'react'
import { useApiAddTicket, useApiServices } from '../hooks/useApi'
import { useStore } from '../hooks/useStore'
import { ToastContainer, toast } from 'react-toastify'
import { Confirmation } from './Confirmation'

const International: React.FC = () => {
  const { data } = useApiServices()
  const services = data?.data
  const { trigger: triggerAddTicket } = useApiAddTicket()
  const setNewTicket = useStore(state => state.setNewTicket)
  const [step, setStep] = useState(1)
  const [selection, setSelection] = useState<string | null>(null)
  const [subCategory, setSubCategory] = useState<string | null>(null)

  const handleSelectImportExport = (type: string) => {
    setSelection(type)
    setStep(2)
  }

  const handleSubCategorySelection = (category: string) => {
    setSubCategory(category)
    setStep(3)
  }

  const handleBack = () => {
    setStep(prevStep => Math.max(1, prevStep - 1))
    if (step === 3) setSubCategory(null)
    if (step === 2) setSelection(null)
  }

  const onSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerAddTicket(
      // @ts-ignore
      { service: event.target.value },
      {
        onSuccess: res => {
          if (res.code === 201) {
            setNewTicket(res.data)
            setStep(3)
          } else {
            toast.error(res.message || 'Something went wrong', {
              position: toast.POSITION.TOP_RIGHT,
            })
          }
        },
        onError: res => {
          toast.error(res.message || 'Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          })
        },
      }
    )
  }

  return (
    <div className='flex flex-col gap-20'>
      <ToastContainer />

      <div className='flex items-center'>
        {step === 2 && (
          <button
            onClick={handleBack}
            className='bg-red-primary p-6 rounded-md'
          >
            <p className='text-4xl font-semibold text-white'>BACK</p>
          </button>
        )}

        <div className='flex items-center justify-center p-8 gap-4'>
          <div className='flex items-center'>
            <div className='w-10 h-10 flex items-center justify-center rounded-full bg-green-500'>
              <p className='text-xl text-white'>✔</p>
            </div>
            <span className='ml-2 text-3xl font-semibold'>International</span>
          </div>

          <span className='text-lg font-semibold'>---------</span>

          <div className='flex items-center'>
            <div
              className={`w-10 h-10 flex items-center justify-center rounded-full ${
                step >= 2 ? 'bg-green-500' : 'bg-gray-300'
              }`}
            >
              {step >= 2 ? <p className='text-xl text-white'>✔</p> : ''}
            </div>
            <span className='ml-2 text-3xl font-semibold'>
              {step >= 2 ? selection : 'Import/Export'}
            </span>
          </div>

          <span className='text-lg font-semibold'>---------</span>

          <div className='flex items-center'>
            <div
              className={`w-10 h-10 flex items-center justify-center rounded-full ${
                step === 3 ? 'bg-green-500' : 'bg-gray-300'
              }`}
            >
              {step === 3 ? <p className='text-xl text-white'>✔</p> : ''}
            </div>
            <span className='ml-2 text-3xl font-semibold'>
              {step >= 3 ? subCategory : 'Services'}
            </span>
          </div>

          <span className='text-lg font-semibold'>---------</span>

          <div className='flex items-center'>
            <div
              className={`w-10 h-10 flex items-center justify-center rounded-full ${
                step === 3 ? 'bg-green-500' : 'bg-gray-300'
              }`}
            >
              {step === 3 ? <p className='text-xl text-white'>✔</p> : ''}
            </div>
            <span className='ml-2 text-3xl font-semibold'>Printing</span>
          </div>
        </div>
      </div>

      {step === 1 && (
        <div className='flex flex-col items-center gap-10'>
          <p className='text-3xl font-semibold text-center'>
            Select Import or Export
          </p>

          <button
            className='bg-navy text-white p-4 rounded-md w-[540px]'
            onClick={() => handleSelectImportExport('Import')}
          >
            <p className='text-4xl font-semibold text-white'>Import</p>
          </button>

          <button
            className='bg-navy text-white p-4 rounded-md w-[540px]'
            onClick={() => handleSelectImportExport('Export')}
          >
            <p className='text-4xl font-semibold text-white'>Export</p>
          </button>
        </div>
      )}

      {step === 2 && (
        <div className='flex flex-col gap-10'>
          <p className='text-3xl font-semibold text-center'>
            {selection === 'Import'
              ? 'Select a service for Import'
              : 'Select a service for Export'}
          </p>

          {selection === 'Import' ? (
            <div className='flex flex-col items-center gap-10'>
              <ul className='flex flex-col gap-10 items-center justify-center'>
                {services &&
                  services
                    .filter(service => service.code.startsWith('I-IM'))
                    .map((service, id) => (
                      <li
                        key={id}
                        onClick={() => handleSubCategorySelection(service.name)}
                        className='relative flex items-center justify-center cursor-pointer'
                      >
                        <input
                          type='checkbox'
                          name='range'
                          value={service._id}
                          onChange={onSubmit}
                          className='absolute inset-0 w-full h-full opacity-0 cursor-pointer'
                        />
                        <span className='bg-navy rounded-md p-6 w-[540px]'>
                          <p className='text-4xl font-semibold text-white text-center'>
                            {service.name}
                          </p>
                        </span>
                      </li>
                    ))}
              </ul>
            </div>
          ) : (
            <ul className='grid grid-cols-2 gap-10 items-center justify-center'>
              {services &&
                services
                  .filter(
                    service =>
                      service.code.startsWith('I-EX') ||
                      service.code.startsWith('I-IM-EX')
                  )
                  .map((service, id) => (
                    <li
                      key={id}
                      onClick={() => handleSubCategorySelection(service.name)}
                      className='relative flex items-center justify-center cursor-pointer'
                    >
                      <input
                        type='checkbox'
                        name='range'
                        value={service._id}
                        onChange={onSubmit}
                        className='absolute inset-0 w-full h-full opacity-0 cursor-pointer'
                      />
                      <span className='bg-navy rounded-md p-6 w-[540px]'>
                        <p className='text-4xl font-semibold text-white text-center'>
                          {service.name}
                        </p>
                      </span>
                    </li>
                  ))}
            </ul>
          )}
        </div>
      )}

      {step === 3 && (
        <div>
          <Confirmation />
        </div>
      )}
    </div>
  )
}

export default International
