import { ticketAction } from '../kioskActionTypes'
const initialState = {
  alertListing: {},
  loader: false,
  loginStatus: false,
}

export default function tickets(state = initialState, { type, payload }) {
  switch (type) {
    case ticketAction.TICKET_GENERATE_INITIATE: {
      return {
        ...state,
        loader: true,
      }
    }
    case ticketAction.TICKET_GENERATE_SUCCESS: {
      return {
        ...state,
        loader: false,
      }
    }
    case ticketAction.TICKET_GENERATE_FAILURE: {
      return {
        ...state,
        loader: false,
      }
    }
    default:
      return state
  }
}
