import { useEffect } from 'react'
import '../App.css'
import { TicketList } from './TicketList'
import {
  useApiCurrentDateTickets,
  useApiTicketIsCalledUpdate,
  useApiServices,
  useApiCounters,
} from '../hooks/useApi'
import Footer from './Footer'
import QantasLogo from '../assets/images/qantas_logo.png'

const callText = (speechText: string) => {
  let speech = new SpeechSynthesisUtterance()
  speech.volume = 1
  speech.rate = 0.9
  speech.pitch = 1
  speech.text = speechText
  speech.voice = window.speechSynthesis.getVoices()[0] || null
  window.speechSynthesis.speak(speech)
}

const TicketQueue = () => {
  const { data: ticketsRes } = useApiCurrentDateTickets()
  const tickets = ticketsRes?.data || []
  const { data: servicesRes } = useApiServices()
  const parsedServices = servicesRes?.parsedIdArray || {}
  const { data: countersRes } = useApiCounters()
  const parsedCounters = countersRes?.parsedIdArray || {}
  const { trigger: triggerTicketIsCalledUpdate } = useApiTicketIsCalledUpdate()

  const uncalledAcceptedTickets = tickets.filter(
    ticket => ticket.ticketStatus === 'accepted' && !ticket.isCalled
  )

  useEffect(() => {
    uncalledAcceptedTickets.forEach(
      ({ service, incrementId, assignedCounter = '', _id }) => {
        const ticketName = `${
          parsedServices[service.toString()]?.code || ''
        }${incrementId}`
        const counterName = parsedCounters[assignedCounter]?.name || ''
        const speechText = `${ticketName} to counter ${counterName}`
        callText(speechText)
        triggerTicketIsCalledUpdate(
          // @ts-ignore
          {
            ticketId: _id,
            isCalled: true,
          }
        )
      }
    )
  })

  return (
    <div className='flex flex-col h-screen w-screen'>
      <div className='flex flex-col lg:flex-row flex-1 min-h-0'>
        <div className='flex flex-col items-center w-2/4 overflow-auto shrink-0'>
          <div className='bg-black h-[550px] w-full'></div>
          <TicketList title='Missed Queue' ticketStatus='skipped' />
        </div>

        <div className='flex flex-col items-center w-3/5 overflow-auto'>
          <img src={QantasLogo} alt='Qantas' width={700} height={200} />
          <TicketList title='Now Serving' ticketStatus='accepted' />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TicketQueue
