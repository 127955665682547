import { ITicketMeta } from 'schema'
import {
  useApiCurrentDateTickets,
  useApiCounters,
  useApiServices,
} from '../hooks/useApi'

type Props = {
  title: string
  ticketStatus?: ITicketMeta['ticketStatus']
}

export const TicketList = ({ title, ticketStatus }: Props) => {
  const { data: ticketsRes, error, isLoading } = useApiCurrentDateTickets()
  const tickets = ticketsRes?.data || []
  const { data: servicesRes } = useApiServices()
  const parsedServices = servicesRes?.parsedIdArray || {}
  const { data: countersRes } = useApiCounters()
  const parsedCounters = countersRes?.parsedIdArray || {}
  const filteredTickets = ticketStatus
    ? tickets.filter(ticket => ticket.ticketStatus === ticketStatus)
    : tickets

  if (error || (!isLoading && ticketsRes?.code !== 200))
    console.error(`[TicketList] ${error} ${ticketsRes}`)

  return (
    <div className='flex flex-col flex-1 w-full min-h-0 text-center text-4xl leading-none font-semibold'>
      <div className='flex flex-row bg-red-secondary text-white *:p-6 *:w-1/2'>
        <div> {title}</div>
        <div> Counter </div>
      </div>

      <div className='flex flex-col flex-1 min-h-0 overflow-auto'>
        {filteredTickets?.map(
          ({ assignedCounter = '', incrementId, service }, i) => {
            // TODO: Improve api type checking
            const counterName = parsedCounters[assignedCounter]?.name || '??'
            const ticketName = `${
              parsedServices[service.toString()]?.code
            }${incrementId}`

            return (
              <div
                key={i}
                className='flex flex-row odd:bg-gray-100 *:p-6 *:font-roboto *:w-1/2 *:border'
              >
                <div>{ticketName}</div>
                <div className='text-red-bocq'>{counterName}</div>
              </div>
            )
          }
        )}
      </div>
    </div>
  )
}
