import React from 'react'
import Logo from '../assets/images/qantas_logo.png'

type TicketData = {
  ticketType: string
  ticket: string
  service: string
}

type Props = {
  ticket: TicketData
}

export class TicketPrintComponent extends React.PureComponent<
  { ticket: TicketData },
  { currentTime: string }
> {
  constructor(props: Props) {
    super(props)
    var today = new Date()
    var time =
      today.getDate() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getFullYear() +
      ' ' +
      today.getHours() +
      ':' +
      today.getMinutes() +
      ':' +
      today.getSeconds()
    this.state = {
      currentTime: time,
    }
  }

  render() {
    return (
      <table style={{ width: '950px', marginLeft: '20px', marginTop: '20px' }}>
        <tbody>
          <tr>
            <td
              style={{
                textAlign: 'center',
                width: '100%',
                paddingBottom: '15px',
                marginBottom: '5px',
              }}
            >
              <img style={{ width: '1000px' }} src={Logo} />
            </td>
          </tr>
          <tr>
            <td
              style={{
                textAlign: 'center',
                width: '100%',
                paddingBottom: '5px',
              }}
            >
              <h1 style={{ margin: '0', fontSize: '80px' }}>Ticket Number</h1>
            </td>
          </tr>
          <tr>
            <td
              style={{
                textAlign: 'center',
                width: '100%',
                paddingBottom: '5px',
              }}
            >
              <p style={{ fontSize: '80px', color: 'grey', margin: '0' }}>
                {this.props.ticket.ticketType}
                {this.props.ticket.ticket}
              </p>
            </td>
          </tr>

          <tr>
            <td
              style={{
                textAlign: 'center',
                width: '100%',
                paddingBottom: '5px',
              }}
            >
              <h6
                style={{
                  margin: '0',
                  fontSize: '80px',
                  color: '#000',
                  fontWeight: '400',
                }}
              >
                <span style={{ paddingRight: '10px', fontWeight: '600' }}>
                  service :
                </span>
                {this.props.ticket.service}
              </h6>
            </td>
          </tr>

          <tr>
            <td
              style={{
                textAlign: 'center',
                width: '100%',
                paddingBottom: '5px',
              }}
            >
              <p
                style={{
                  margin: '0',
                  fontSize: '80px',
                  color: 'gray',
                  fontWeight: '400',
                  fontStyle: 'italic',
                }}
              >
                {this.state.currentTime}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}
