import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../App.css'
import Navbar from './Navbar'
import International from './International'
import Domestic from './Domestic'

export const CreateTicket: React.FC = () => {
  const [step, setStep] = useState(1)
  const [selectedDestination, setSelectedDestination] = useState<string | null>(
    null
  )

  const handleTouchScreenClick = () => {
    setStep(2)
  }

  const handleDestinationClick = (destination: string) => {
    setSelectedDestination(destination)
    setStep(3)
  }

  return (
    <div className='h-screen w-screen'>
      <ToastContainer />
      <Navbar />
      <div className='w-full h-16 bg-[#323232]'></div>

      <div className='flex flex-col items-center justify-center p-6'>
        {step === 1 && (
          <div className='p-20'>
            <button
              onClick={handleTouchScreenClick}
              className='bg-red-primary p-10 rounded-md'
            >
              <p className='text-6xl font-semibold text-white'>
                Touch Screen to Start
              </p>
            </button>
          </div>
        )}

        {step === 2 && (
          <div className='flex flex-col gap-4 p-20'>
            <p className='text-6xl font-semibold text-red-primary mb-10'>
              Select a destination
            </p>

            <div className='flex flex-col gap-10'>
              <button
                onClick={() => handleDestinationClick('International')}
                className='bg-navy p-6 rounded-md'
              >
                <p className='text-4xl font-semibold text-white'>
                  International
                </p>
              </button>

              {/* <button
                onClick={() => handleDestinationClick('Domestic')}
                className='bg-navy p-6 rounded-md'
              >
                <p className='text-4xl font-semibold text-white'>Domestic</p>
              </button> */}
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            {selectedDestination === 'International' && <International />}
            {/* {selectedDestination === 'Domestic' && <Domestic />} */}
          </div>
        )}
      </div>
    </div>
  )
}
