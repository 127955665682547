import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { ITicketMeta } from 'schema'

interface State {
  newTicket: ITicketMeta | null | undefined
  setNewTicket: (newTicket: ITicketMeta | null | undefined) => void
  resetStore: () => void
}

const initialState = {
  newTicket: null,
}

export const useStore = create<State>()(
  devtools(
    persist(
      set => ({
        ...initialState,
        resetStore: () => set(() => initialState),
        setNewTicket: newTicket => set(state => ({ ...state, newTicket })),
      }),
      {
        name: 'use-store',
      }
    )
  )
)
