import QantasLogo from '../assets/images/qantas_logo.png'
import { NewsTicker } from './NewsTicker'
import Clock from 'react-live-clock'

const Footer: React.FC = () => {
  return (
    <div className='flex justify-end h-[114px]'>
      <div className='flex items-center bg-white pp-3 w-[20%]'>
        <figure>
          <img src={QantasLogo} alt='Qantas' height={500} width={500} />
        </figure>
      </div>

      <div className='flex items-center text-lg w-[60%] bg-charcoal text-white'>
        <NewsTicker />
      </div>

      <div className='flex flex-col items-center justify-center p-3 w-[20%]'>
        <h1 className='text-xl'>Sydney Australia</h1>
        <Clock
          format={'HH:mm:ss'}
          style={{ fontSize: '2.5em' }}
          ticking={true}
          timezone={'Australia/Sydney'}
        />
      </div>
    </div>
  )
}

export default Footer
