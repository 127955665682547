import React from 'react'
import './App.css'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import TicketQueue from './components/TicketQueue'
import { CreateTicket } from './components/CreateTicket'
import { Confirmation } from './components/Confirmation'

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/Ticket' element={<CreateTicket />} />
          <Route path='/Confirmation' element={<Confirmation />} />
          <Route path='/' element={<TicketQueue />} />
          <Route path='/en' element={<TicketQueue />} />
          <Route path='/cn' element={<TicketQueue />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  )
}

export default App
