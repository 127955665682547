import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { url } from './../config/config'
import {
  postFetcher,
  fetchPostWithToken,
  arrayFetcher,
} from './../components/fetchers'
import { Ticket, NewsList } from 'boc_queue'
import {
  IServiceMeta,
  ITicketMeta,
  ICounterMeta,
  ApiResParsedIdArray,
  ApiRes,
} from 'schema'

export const useApiCurrentDateTickets = () =>
  useSWR<ApiRes<ITicketMeta[]>, Error>(`${url}web/tickets/currentdate`, {
    refreshInterval: 10000,
  })

export const useApiAddTicket = (options?: any) => {
  return useSWRMutation<ApiRes<ITicketMeta>, Error>(
    `${url}web/ticket/add`,
    (key: string, options: any) => fetchPostWithToken(key, null, options),
    options
  )
}

export const useApiSkippedTickets = () =>
  useSWR<ApiRes<Ticket[]>, Error>(`${url}web/ticket/skip/list`, {
    refreshInterval: 10000,
  })

export const useApiNews = () =>
  useSWR<ApiRes<NewsList>, Error>(`${url}admin/alert/lists`, {
    refreshInterval: 20000,
  })

export const useApiTicketRequest = () =>
  useSWRMutation(`${url}web/ticket/add`, postFetcher)

export const useApiServices = () =>
  useSWR<ApiResParsedIdArray<IServiceMeta>, Error>(
    `${url}admin/services`,
    arrayFetcher,
    {
      refreshInterval: 20000,
    }
  )

export const useApiCounters = () =>
  useSWR<ApiResParsedIdArray<ICounterMeta>, Error>(
    `${url}admin/counters`,
    arrayFetcher,
    {
      refreshInterval: 20000,
    }
  )

export const useApiTicketVoiceUpdate = (options?: any) => {
  return useSWRMutation<ApiRes<Ticket>, Error>(
    `${url}web/ticket/voice/update`,
    (key: string, options: any) => fetchPostWithToken(key, null, options),
    options
  )
}

export const useApiTicketIsCalledUpdate = (options?: any) => {
  return useSWRMutation<ApiRes<Ticket>, Error>(
    `${url}admin/ticket/iscalledupdate`,
    (key: string, options: any) => fetchPostWithToken(key, null, options),
    options
  )
}
